import axios from 'axios';
import { ActionTree } from 'vuex';
import { CartState, RootState } from '../types';

export const actions: ActionTree<CartState, RootState> = {
    GET_CART({ commit }) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}bestelsite/carts`)
                .then((resp: any) => {
                    commit('LOADING_STOP');
                    resolve(resp);
                })
                .catch((err) => {
                    commit('AUTH_ERROR', err);
                    reject(err);
                });
        });
    },
    CART_UPDATE({ commit, dispatch }, data) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            axios.put(`${process.env.VUE_APP_API_URL}bestelsite/carts`, data)
                .then((resp: any) => {
                    commit('LOADING_STOP');
                    dispatch('CART_COUNT');
                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');
                    reject(err);
                });
        });
    },
    CART_COUNT({ commit, state }) {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}bestelsite/carts/count`)
                .then((resp: any) => {
                    state.count = resp.data;
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    async CART_SEND({ commit, dispatch }, data) {
        commit('LOADING_START');

        const cart = await dispatch('GET_CART');
        const structuredItems = cart.data?.data?.map((itemData, index) => {
            const { item } = itemData;

            return {
                item_id: item.no,
                item_name: item.name,
                index,
                item_brand: item.color,
                item_category: item.country.name,
                item_category2: item.region.name,
                item_category3: item.estate.name,
                item_category4: `${item.alcohol_percentage}%`,
                item_category5: item.grapes.map((grape) => grape.name).join(', '),
                price: itemData.price,
                quantity: itemData.quantity,
            };
        });

        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            axios.post(`${process.env.VUE_APP_API_URL}bestelsite/orders`, data)
                .then((resp: any) => {
                    commit('LOADING_STOP');
                    dispatch('CART_COUNT');
                    const dataLayer = window.dataLayer || [];

                    dataLayer.push({ ecommerce: null });
                    dataLayer.push({
                        event: 'purchase',
                        ecommerce: {
                            transaction_id: 'T_12345',
                            value: cart.total_amount,
                            currency: 'EUR',
                            items: structuredItems,
                        },
                    });
                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');
                    dispatch('CART_COUNT');
                    reject(err);
                });
        });
    },
    GET_TIMES({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}bestelsite/times`)
                .then((resp: any) => {
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
